<template>
  <b-modal
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    hide-header
    hide-footer
    size="xl"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
  
         <b-row class="mb-1" v-for="(suggestion, index) in assigneeOptions" :key="index">
         <b-col class="pt-1" cols="12" md="2">
         {{suggestion.fullName}}

         </b-col>
         <b-col cols="12" md="10">
               <v-select
                    v-model="select[suggestion.fullName]"
                    :options="suggestion.candidateComments"
                    :clearable="false"
                    input-id="id"
                    label="Comment"
                     return-object
                     v-on:input="changeselect(suggestion.candidateId,select[suggestion.fullName].comment)"
                  >
                 <template #option="{ comment }">
                      <span class="ml-50 d-inline-block align-middle">
                        {{ comment }}</span
                      >
                    </template>

                    <template #selected-option="{ comment }">
                      <span class="ml-50 d-inline-block align-middle">
                        {{ comment }}</span
                      >
                    </template>
                  </v-select>

         </b-col>
         </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="changeData(reportList),isAddNewUserSidebarActive=false"
            >
              Report
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        
    
    </template>
  </b-modal>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import { ref } from "@vue/composition-api";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    flatPickr,
    BSidebar,
    ToastificationContent,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    BFormTextarea,
  },
    data() {
    return {
     select:{},
      reportList: [],
    };
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
   changeData: {
        type: Function
        ,
      required: true,
      },
    planOptions: {
      type: Array,
      required: true,
    },
    assigneeOptions: {
      type: Array,
      required: true,
    },
  },
 methods: {
    changeselect(candidateId,comment) {
      
       this.reportList.push({candidateId,comment})
    },
 },
  setup(props, { emit }) {
    const blankUserData = {
      title: "",
      companyId: 0,
      description: "",
      BeginDateUtc:new Date(),
      EndDateUtc:moment(new Date()).add(14, 'days'),
    };

    const companies = ref([{ name: "amatis", id: 4 }]);

    const companyData = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      companyData.value = JSON.parse(JSON.stringify(blankUserData));
    };

   

    const { refFormObserver, getValidationState } =
      formValidation(resetuserData);

    return {
      companyData,
    
      companies,
      refFormObserver,
      getValidationState,

    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
