<template>
    <b-modal @hide="$emit('close')" title="Candidate Information" visible 
    ok-only
    >
                <table style="width: 100%">
                    <tr>
                        <td>Fullname</td><td>: {{ candidate.fullName }}</td>
                    </tr>
                    <tr>
                        <td>Title</td><td>: {{ candidate.title }}</td>
                    </tr>
                    <tr>
                        <td>Mobile No</td><td>: {{ candidate.mobileNumber }}</td>
                    </tr>
                    <tr>
                        <td>University</td><td>: {{ candidate.university }}</td>
                    </tr>
                    <tr>
                        <td>University Department</td><td>: {{ candidate.universityDepartment }}</td>
                    </tr>
                    <tr>
                        <td>Current Company</td><td>: {{ candidate.currentCompany }}</td>
                    </tr>
                    <tr>
                        <td>BirtDate</td><td>: {{ dateFormat(candidate.birthDate) }}</td>
                    </tr>
                </table>
                <hr/>       
                <table style="width: 100%; margin-top: 10px;" v-for="(value, key) in comments">
                    <tr>
                        <td style="text-align: left;font-weight: bold;">{{ value.title }}:</td>
                        <td style="text-align: right;"><small class="text-muted">{{ dateFormat(value.createdOn) }}</small></td>
                    </tr>
                    <tr>
                        <td colspan="2" style="text-align: justify;">{{ value.comment }}</td>
                    </tr>
                </table>
               
    </b-modal>
</template>

<script>
import moment from "moment";

export default {
    props: {
        candidate: {
            type: Object,
            required: true
        },
        comments: {
            type: Array,
            required: true
        }
    },
    methods: {
        dateFormat(date) {
            return moment(String(date))
                ? moment(String(date)).format("DD/MM/YYYY HH:mm")
                : date;
        }
    }
};
</script>

<style scoped>
/* İsteğe bağlı stil ekleyebilirsiniz */
</style>